(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/common/assets/javascripts/grasroten.js') >= 0) return;  svs.modules.push('/components/oddset/common/assets/javascripts/grasroten.js');
"use strict";


const {
  getGrasrotenMessageForDialogOrToaster
} = svs.sport.sport_common;

const showGrasrotenDialog = async () => {
  const grasroten = await getGrasrotenMessageForDialogOrToaster();
  if (grasroten.hasSponsorship) {
    var _svs$sport$sport_comm;
    const dialog = await ((_svs$sport$sport_comm = svs.sport.sport_common) === null || _svs$sport$sport_comm === void 0 ? void 0 : _svs$sport$sport_comm.getContextualGrasrotenDialog());
    dialog.options.autoClose = 4000;
    dialog.options.area = svs.components.dialog.area.TOASTER;
    svs.components.dialog.api.add(dialog);
  } else {
    new svs.sport.GrassrootsToaster().show();
  }
};
setGlobal('svs.components.oddset.common.grasroten', showGrasrotenDialog);

 })(window);